<template>
  <Browse
    :columns="[
      'expense_type',
      'date',
      'amount',
      'concept',
      'beneficiary',
      'note',
    ]"
    :formats="{
      date: 'Date',
      amount: 'Money',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="expenses"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
